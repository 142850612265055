export default {
  data() {
    return {
      activeItem: null,
      openMenuIndex: null,
      isMegaMenuContentId: null,
    };
  },
  mounted() {
    // Only add listener if we're on client-side
    if (typeof document !== 'undefined') {
      document.addEventListener('click', this.handleCloseMenu);
    }
  },
  beforeUnmount() {
    // Clean up listener if we're on client-side
    if (typeof document !== 'undefined') {
      document.removeEventListener('click', this.handleCloseMenu);
    }
  },
  methods: {
    getMenuItemClass(item, index) {
      if (!item) return '';

      const baseClasses = ['text-nowrap'];
      const menuType = item?.openBy === 'click' ? 'megamenu-item-click' : 'megamenu-item';
      const customClass = item.class || 'mx-auto px-3';
      const isActive = { 'megamenu-show': this.openMenuIndex === index };

      return [menuType, ...baseClasses, customClass, isActive];
    },

    toggleMenu(index) {
      if (typeof index !== 'number') return;

      this.openMenuIndex = this.openMenuIndex === index ? null : index;

      // Get items from either navLinks (Navbar) or megamenu.items (Megamenu)
      const items = this.navLinks || (this.megamenu && this.megamenu.items) || [];
      this.activeItem = items[index];

      // Safely check for HTML content
      if (this.activeItem && this.activeItem.html) {
        this.isMegaMenuContentId = this.activeItem.html.includes('megamenu-show');
      }
    },

    handleMegaMenuContent(event) {
      if (!event || !event.target) return;

      const element = event.target;

      if (this.isMegaMenuContentId) {
        let parent = element.parentElement;
        while (parent) {
          if (parent.id === 'megamenuWrapper') {
            this.closeMenu();
            break;
          } else if (parent.classList && parent.classList.contains('megamenu-show')) {
            break;
          }
          parent = parent.parentElement;
        }
      }

      if (event.target?.tagName === 'A') {
        this.closeMenu();
      }
    },

    handleCloseMenu(event) {
      if (!event || !this.$el || !event.target) return;

      if (!this.$el.contains(event.target)) {
        this.closeMenu();
      }
    },

    closeMenu() {
      if (this.openMenuIndex !== null) {
        this.openMenuIndex = null;
      }
    },

    validHtml(html) {
      if (!html) return '';

      try {
        const div = document.createElement('div');
        div.innerHTML = html;
        return div.innerHTML;
      } catch {
        return html;
      }
    },
  },
};
