
  import CategoriesMenuItems from './../navbar/CategoriesMenuItems';
  import BDropdownHover from './../BDropdownHover';
  import megamenuMixin from './megamenuMixin';
  export default {
    name: 'Megamenu2',
    components: {
      CategoriesMenuItems,
      BDropdownHover,
    },
    data() {
      return {
        activeItem: null,
        openMenuIndex: null,
        isMegaMenuContentId: null,
      };
    },
    props: {
      megamenu: {
        type: Object,
        default: () => ({}),
        required: false,
      },
    },
    mixins: [megamenuMixin],
    mounted() {
      document.addEventListener('click', this.handleCloseMenu);
    },
    beforeUnmount() {
      document.removeEventListener('click', this.handleCloseMenu);
    },
    methods: {
      handleMegaMenuContent(event) {
        const element = event.target;

        if (this.isMegaMenuContentId) {
          let parent = element.parentElement;
          while (parent) {
            if (parent.id === 'megamenuWrapper') {
              this.closeMenu();
              parent = false;
            } else if (parent.classList.contains('megamenu-show')) {
              parent = false;
            }
            parent = parent.parentElement;
          }
        }

        if (event.target?.tagName === 'A') {
          this.closeMenu();
        }
      },
      handleCloseMenu(event) {
        if (!this.$el.contains(event.target)) {
          this.closeMenu();
        }
      },
      closeMenu() {
        if (this.openMenuIndex !== null) {
          this.openMenuIndex = null;
        }
      },
    },
  };
